.plans-from-profile{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}
.subscribe-extra-info{
    margin-bottom: 40px;
}

.deleteMyAccount{
    color:#f44336;
    text-decoration: underline;
    font-size: 14px;
    font-style: italic;
    margin-top: 80px;
}